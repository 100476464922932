import {
  json,
  redirect,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node';
import { useSearchParams } from '@remix-run/react';
import { safeRedirect } from 'remix-utils/safe-redirect';
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx';
import { requireAnonymous, sessionKey } from '#app/utils/auth.server.ts';
import {
  ProviderConnectionForm,
  providerNames,
} from '#app/utils/connections.tsx';
import { combineResponseInits } from '#app/utils/misc.tsx';
import { authSessionStorage } from '#app/utils/session.server.ts';

export async function handleNewSession(
  {
    request,
    session,
    redirectTo,
    remember,
  }: {
    request: Request;
    session: { userId: string; id: string; expirationDate: Date };
    redirectTo?: string;
    remember: boolean;
  },
  responseInit?: ResponseInit,
) {
  const authSession = await authSessionStorage.getSession(
    request.headers.get('cookie'),
  );
  authSession.set(sessionKey, session.id);

  return redirect(
    safeRedirect(redirectTo),
    combineResponseInits(
      {
        headers: {
          'set-cookie': await authSessionStorage.commitSession(authSession, {
            expires: remember ? session.expirationDate : undefined,
          }),
        },
      },
      responseInit,
    ),
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request);
  return json({});
}

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  return (
    <div className="flex min-h-full flex-col justify-center pb-32 pt-20">
      <div className="mx-auto w-full max-w-md">
        <div className="flex flex-col gap-3 text-center">
          <h1 className="text-h1">Welcome back!</h1>
          <p className="text-body-md text-muted-foreground">
            Sign in with your Google Account to continue.
          </p>
        </div>
        <div>
          <div className="mx-auto w-full max-w-md px-8">
            <ul className="mt-5 flex flex-col gap-5 border-border py-3">
              {providerNames.map(providerName => (
                <li key={providerName}>
                  <ProviderConnectionForm
                    type="Login"
                    providerName={providerName}
                    redirectTo={redirectTo}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: 'Login to RHSR' }];
};

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
